import React, { useState } from 'react';
import { ReactComponent as TelegramLogo } from '../assets/tglogo.svg';
import InputField from './InputField';
import { useHistory, Link } from 'react-router-dom';


const Form = ({ insertUnames }) => {
    const [inputList, setInputList] = useState([{ userName: '' }])
    const [showWarn, triggerWarn] = useState(false);
    const history = useHistory()

    // handle input change
    const handleInputChange = (e, index) => {
        triggerWarn(false);
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
        triggerWarn(false);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        inputList.length < 4 ? setInputList([...inputList, { userName: "" }]) : triggerWarn(true);
    };

    const onSubmit = (event) => {
        event.preventDefault();
        insertUnames(inputList);
        history.push('/counter')
    }

    return (
        <div className='w-full max-w-xs md:max-w-sm'>
            <Link path='/'>
                <h1 className='text-center text-2xl mb-12 font-bold font-title text-gray-600'>Real-Time Telegram Subscriber Counter</h1>
            </Link>
            <form className='bg-white shadow-sm rounded-xl px-8 pt-6 pb-8 mb-4' onSubmit={onSubmit}>
                <div className='flex justify-center'>
                    <TelegramLogo className='h-16 w-16 mb-6' />
                </div>
                {
                    inputList.map((val, index) => {
                        return (
                            <InputField key={index}
                                val={val} index={index}
                                totalLength={inputList.length}
                                handleRemoveClick={handleRemoveClick}
                                handleAddClick={handleAddClick}
                                handleInputChange={handleInputChange}
                            />
                        )
                    })
                }
                {
                    showWarn && <div className="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md" role="alert">
                        <div className="flex">
                            <div className="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                            <div>
                                <p className="font-bold text-sm">Limit exceeded</p>
                                <p className="text-xs">Only 4 inputs are allowed</p>
                            </div>
                        </div>
                    </div>
                }
                <div className="flex items-center justify-between">
                    <button className="submit-btn cursor-pointer py-2 px-4 block mt-6 bg-accent hover:bg-blue-600 text-white font-bold w-full text-center rounded transition transform hover:scale-95 duration-300" type="submit">
                        Let's Go
                    </button>
                </div>

            </form>
            <p className="text-center text-gray-500 text-xs">
                &copy;2021 InFoTel. All rights reserved.
            </p>
        </div>
    )
}

export default Form
