import React from 'react';
import { TrashIcon, PlusSmIcon } from '@heroicons/react/outline';


const InputField = ({ val, totalLength, index, handleRemoveClick, handleAddClick, handleInputChange }) => {
    return (
        <div className='flex mb-4 justify-center'>
            <div className="input-container flex md:px-2">
                <span className='py-2 px-2 bg-gray-300 text-gray-500 font-bold appearance-none border border-r-0 rounded-lg rounded-r-none'>@</span>
                <input className={`user-input input-box-${index} border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none md:rounded-r-lg`} type="text" placeholder={`username ${index !== 0 ? index + 1 : ''}`} name="userName" value={val.userName} onChange={e => handleInputChange(e, index)} required />
            </div>
            {
                index !== 0 ? <button className={`del-btn bg-red-500 hover:bg-red-700 ${totalLength === index + 1 ? null : 'rounded-r-lg md:rounded-md'} transition-colors duration-300`} type="button" onClick={() => handleRemoveClick(index)}>
                    <TrashIcon className='w-7 h-4 text-white' /> 
                </button> : totalLength !== 1 && <button className={`del-btn bg-red-500 hover:bg-red-700 ${totalLength === index + 1 ? null : 'rounded-r-lg md:rounded-md'} transition-colors duration-300`} type="button" onClick={() => handleRemoveClick(index)}>
                    <TrashIcon className='w-7 h-4 text-white' />
                </button>
            }
            {
                totalLength === index + 1 && <button className={`add-btn ${index < 3 ? 'bg-green-500 hover:bg-green-700' : 'bg-gray-500 hover:bg-gray-700'} rounded-r-lg ${index === 0 ? 'md:rounded-lg': null} transition-colors duration-300`} type="button" onClick={handleAddClick}>
                    <PlusSmIcon className='w-7 h-4 text-white' />
                </button>
            }
        </div>
    )
}

export default InputField
