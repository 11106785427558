import React, {useEffect} from 'react';
import Card from './Card';
import { useHistory } from 'react-router-dom'

const Counter = ({ userNames }) => {
    const history = useHistory();
    useEffect(() => {
       if (userNames.length === 0) {
            history.push('/')
       }
    }, [userNames, history])
    return (
        <div className={`w-full mt-20 md:mt-28 pb-10`}>
            <div className='card-conatiner flex flex-wrap justify-center md:gap-24 gap-6'>
                {
                    userNames.map((e) => {
                        return (
                            <Card key={e.userName} userName={e.userName} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Counter
