import React from 'react'
import { ArrowSmLeftIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'

const Navbar = ({ toggler }) => {
    return (
        <nav>
            <div className="flex justify-between h-14 px-6 items-center mt-2">
                <div className="flex items-center space-x-8">
                    <Fade right duration={500}>
                        <Link to='/' onClick={() => toggler(true)}>
                            <ArrowSmLeftIcon className='h-7 w-7 font-bold cursor-pointer bg-gray-300 hover:bg-gray-400 rounded-lg transition duration-300' />
                        </Link>
                    </Fade>
                </div>
            </div>
        </nav>
    )
}

export default Navbar