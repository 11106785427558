import { useState } from "react";
import Counter from "./components/Counter";
import Form from "./components/Form";
import Navbar from "./components/NavBar";
import { Route, Switch } from 'react-router-dom'
import Fade from 'react-reveal/Fade'

function App() {
    const [userNameArray, setstate] = useState([]);
    const [isBack, toggleBack] = useState(false);
    return (
        <>
            <Switch>
                <Route path='/' exact>
                    <Fade left={isBack} duration={500}>
                        <div className="flex justify-center my-20 md:my-24 font-ubuntu">
                            <Form insertUnames={setstate} />
                        </div>
                    </Fade>
                </Route>

                <Route path='/counter'>
                    <Navbar toggler={toggleBack} />
                    <Fade right duration={500}>
                        <Counter userNames={userNameArray} key='counter' />
                    </Fade>
                </Route>
            </Switch>
        </>
    );
}

export default App;
