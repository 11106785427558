import React, { useState, useEffect } from 'react'
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css'
import axios from 'axios'

const Card = ({ userName }) => {
    const [loaded, setLoaded] = useState(false);  // dp loader
    const [data, setData] = useState({})
    useEffect(() => {
        const interval = setInterval(() => {
            axios.get(`https://api.pever.tech/tginfo?username=${userName}`).then((res) => {
                setData(res.data)
            });
        }, 2000);
        return () => clearInterval(interval);
    }, [userName])
    return (
        <div className='hover:shadow-2xl w-40 md:w-52 rounded-2xl transition transform hover:-translate-y-3 duration-500'>
            <div className="flex items-center">
                <div className="relative flex flex-col items-center w-full pb-5">
                    <div className="w-full filter hover:opacity-80 transition duration-500">
                        <img src={data.imageUrl} className="h-36 w-80 filter blur object-cover" alt='' />
                    </div>
                    <div className={`h-24 w-24 md rounded-full relative items-end justify-end min-w-max -top-16 flex row-start-1 row-end-3 ${loaded && 'ring-2'} ring-white`}>
                        {!loaded && <svg className="animate-spin text-gray-300 relative" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>}

                        <img className={`h-24 w-24 rounded-full ${loaded ? 'relative' : 'hidden'} transition transform hover:scale-95 duration-500`} onLoad={() => setLoaded(true)} src={data.imageUrl} alt="" />
                    </div>
                    <div className="flex flex-col space-y-1 justify-center items-center -mt-12 w-full overflow-x-hidden">
                        {
                            loaded && <span className="text-md whitespace-nowrap text-gray-800 font-semibold font-days_one">{data.title}</span>
                        }
                        <div className="py-2 flex space-x-2">
                            {
                                loaded && <div className='flex justify-center max-h-max whitespace-nowrap rounded max-w-max border bg-transparent border-accent text-accent px-4 py-1 items-center font-bold'>
                                    <Odometer className="mx-2 font-days_one" value={data.members} format="(,ddd).dd" />
                                </div>
                            }

                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default Card
